.main-content {
    width: 800px;
    margin:50px auto;
    flex: 1;
    align-items: center;
}

.login-content {
    display: flex;
    width: 350px;
}
.login-content > div {
    width: 100%;
}

.header {
    background: white;
}